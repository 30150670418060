import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import './styles.css'
import Accordion from '../../../components/Accordion/Accordion'
import dane from './../../../../static/images/dane.png'
import ucetnictvi from './../../../../static/images/ucetnictvi.png'
import Img from 'gatsby-image'
import { Link } from '@reach/router'
import { margin } from 'styled-system'

const HomepageLanding = ({ photo, ekpLogo }) => (
  <div className="LandingPhoto">
    <div className="row align-items-center">
      <div className="col-xl-6 col-lg-12">
        <div className="container row justify-content-center">
          <div className="h1-mobile col-12">
            <h1 className="h1-mobile">Daňová kontrola</h1>
            <div className="HomePage--Underline" />
            <h2 className="h2-mobile">Jsme specialisté na daňový proces</h2>
            <div className="h1-desktop col-12">
              <h2 className="h2-mobile" style={{ display: 'none' }}>
                EK Partners, s.r.o.
              </h2>
              <h2 style={{ display: 'none' }}>daňoví a účetní poradci</h2>
              <a className="homepage-logo" href="https://www.ekp.cz/">
                <Img fixed={ekpLogo.fixed} alt="Homepage photo" />
              </a>
              <div className="padding-logo"></div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-xl-6 col-lg-12">
        <div className="container row justify-content-center">
          <div className="col-xl-12 col-lg-6 col-sm-12 col-12 wrapper">
            <Link to="/sluzby/">
              <div className="LandingPhoto--Wrap">
                <img src={dane} alt="Služby" />
                <h2>Služby</h2>
              </div>
            </Link>
          </div>
          <div className="col-xl-12 col-lg-6 col-sm-12 col-12 wrapper">
            <Link to="/aktuality/">
              <div className="LandingPhoto--Wrap">
                <img src={ucetnictvi} alt="Aktuality" />
                <h2>Aktuality</h2>
              </div>
            </Link>
          </div>
        </div>
        <br />
      </div>
    </div>
  </div>
)

const HomepageLandingData = () => {
  const { homepageLanding, ekpLogo } = useStaticQuery(graphql`
    query {
      ekpLogo: file(relativePath: { eq: "homepage/ekp_logo_homepage_CZ.png" }) {
        childImageSharp {
          fixed(width: 250) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  return <HomepageLanding ekpLogo={ekpLogo.childImageSharp} />
}

export default HomepageLandingData
